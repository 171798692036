





import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';

@Component
export default class ViewAppraisalRequest extends Mixins(View) {
  iframeUrl = ''

  mounted() {
    const docId = this.$route.query.documentId as string;
    const contactName = this.$route.query.contactName as string;

    this.$router.push(`/appraisals/${contactName.split(' ').join('-').toLowerCase()}/${docId}`);
  }
}
